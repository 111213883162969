export var IVerticalMarketNames;
(function (IVerticalMarketNames) {
    IVerticalMarketNames["COMMERCIAL_REAL_ESTATE"] = "Commercial Real Estate";
    IVerticalMarketNames["FEDERAL_GOVERNMENT"] = "Federal Government";
    IVerticalMarketNames["HEALTHCARE_SYSTEM"] = "Healthcare System";
    IVerticalMarketNames["HIGHER_EDUCATION"] = "Higher Education";
    IVerticalMarketNames["K12_SCHOOLS"] = "Public K-12";
    IVerticalMarketNames["STATE_LOCAL_GOVERNMENT"] = "State & Local Government";
})(IVerticalMarketNames || (IVerticalMarketNames = {}));
export const FriendlyPropertySizesValues = {
    SqFt: 'Sq Ft',
    NumberOfKeys: '# Keys',
    NumberOfUnits: '# Units',
};
export const FriendlyPropertySizesOptions = Object.values(FriendlyPropertySizesValues);
// Should be converted to a boolean
export const FriendlyYesNoValues = {
    Yes: 'Yes',
    No: 'No',
};
export const FriendlyYesNoOptions = Object.values(FriendlyYesNoValues);
