import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/montserrat';
import '@fontsource/mulish';
import theme from '@frontend/design-system/theme/theme';
import { DependenciesProvider } from '@frontend/domain/contexts/Dependencies/DependenciesProvider';
import { FeatureFlagProvider } from '@frontend/domain/contexts/FeatureFlags/FeatureFlagProvider';
import { ToastContainer } from '@frontend/domain/ToastContainer';
import { Authorization } from '@frontend/shared/src/components/Authentication/Authorization';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { authConfig } from './authConfig';
import { environment } from './config/environment';
// import theme from './config/theme';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
const { domain, clientId, authorizationParams: { redirectUri, platformAudience }, } = authConfig;
export const authOptions = {
    domain,
    clientId,
    authorizationParams: {
        redirect_uri: redirectUri,
        audience: platformAudience,
        scope: 'email read:current_user update:current_user_metadata',
    },
};
root.render(_jsx(Provider, Object.assign({ store: store }, { children: _jsx(BrowserRouter, Object.assign({ basename: environment.appBasename }, { children: _jsx(ChakraProvider, Object.assign({ theme: theme }, { children: _jsx(Authorization, Object.assign({ authOptions: authOptions, appBasename: environment.appBasename }, { children: _jsx(DependenciesProvider, Object.assign({ mysiteApi: {
                        baseUrl: environment.apis.mysiteIQAPI,
                        token: '',
                    }, platformApi: {
                        baseUrl: environment.apis.platformAPI,
                        token: '',
                    } }, { children: _jsxs(FeatureFlagProvider, { children: [_jsx(App, {}), _jsx(ToastContainer, {})] }) })) })) })) })) })));
serviceWorkerRegistration.unregister();
