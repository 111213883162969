import { jsx as _jsx } from "react/jsx-runtime";
import { Auth0Provider, } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
export function Authorization({ authOptions, appBasename, children, }) {
    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        if (appState === null || appState === void 0 ? void 0 : appState.returnTo) {
            /* If we're navigating back to an app, the redirect base path would end with
                /<app_name>. The returnTo value will have something like
                /<app_name>/abc/123, which would would redirect us to
                /<app_name>/<app_name>/organization. So, we need to remove
                one of the repeated app name segments with the replace statement.
           */
            appBasename
                ? navigate(appState.returnTo.replace(appBasename, ''))
                : navigate(appState.returnTo);
        }
        else {
            navigate(window.location.pathname);
        }
    };
    return (_jsx(Auth0Provider, Object.assign({}, authOptions, { onRedirectCallback: onRedirectCallback }, { children: children })));
}
